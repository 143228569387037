import { Typography, Card, Box } from '@mui/material'
import PropTypes from 'prop-types'
import LinearProgressWithLabel from '../../sections/@dashboard/app/LinearProgress'
import i18n from 'i18next'

export default function CompletionRate ({ title, data }) {
  const translation = i18n.getDataByLanguage(i18n.resolvedLanguage).summary.routes.rate

  return (
    <Card
      sx={{
        textAlign: 'center',
        bgcolor: '#E9EFF6',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Typography style={{ fontFamily: 'Arial', fontSize: 25, fontWeight: 'bold', color: '#121212', paddingTop: '3%' }}>
        {title}
      </Typography>
      <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '3%' }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: '30% 60%', width: '85%', gap: '10px', paddingBottom: '0.5em', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
          <Typography fontSize={'1.3em'} color='#17385F' sx={{ pb: 0.5, fontWeight: 'bold' }}>
            General
          </Typography>
          <LinearProgressWithLabel variant="determinate" size={13} value={data[0].title} />
        </Box>
        <Box style={{ borderBottom: '1.5px dashed grey', width: '80%' }} />
        <Box sx={{ display: 'grid', gridTemplateColumns: '30% 60%', width: '80%', gap: '10px', marginTop: '5%' }}>
          <Typography fontSize={'1em'} color='#17385F' sx={{ pb: 0.5, fontWeight: 'bold' }}>
            {translation.titles[0]}
          </Typography>
          <LinearProgressWithLabel variant="determinate" size={7} value={data[1].title} />
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '30% 60%', width: '80%', gap: '10px', marginTop: '2px' }}>
          <Typography fontSize={'1em'} color='#17385F' sx={{ pb: 0.5, fontWeight: 'bold' }}>
            {translation.titles[1]}
          </Typography>
          <LinearProgressWithLabel variant="determinate" size={7} value={data[2].title} />
        </Box>
      </Box>

    </Card>
  )
}

CompletionRate.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.number
    })
  )
}
