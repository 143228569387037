import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const RedirectionComponent = ({ redirectBool, redirectPage }) => {
  const navigate = useNavigate()
  if (redirectBool) {
    navigate(redirectPage)
  }
  return null
}

RedirectionComponent.propTypes = {
  redirectBool: PropTypes.bool.isRequired,
  redirectPage: PropTypes.string.isRequired
}

export default RedirectionComponent
