import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material'

const BorderLinearProgress = styled(LinearProgress)(({ theme, size }) => ({
  height: size,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#17385F' : '#308fe8'
  }
}))

export default function LinearProgressWithLabel ({ value, textType = 'h5', size = 10, ...props }) {
  return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <BorderLinearProgress variant="determinate" {...props} size={size} value={value}/>
        </Box>
        <Box>
          <Typography variant={textType} marginLeft={1} fontWeight={'bold'} color='#17385F'>{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  textType: PropTypes.string,
  size: PropTypes.number
}
