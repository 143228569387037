import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'
// @mui
import { Grid, Container } from '@mui/material'
// components
import LoadingCircle from '../components/loading/loadingPage'
// redux
import { useSelector, useDispatch } from 'react-redux'

import { fetchSummaryData } from '../features/summarySlice'
import Usage from '../components/summary/usage'
import Recommandations from '../components/summary/recommandations'
import Routes from '../components/summary/routes'
import RedirectionComponent from '../components/nav-section/redirectionComponent'

export default function Summary () {
  const dispatch = useDispatch()
  const summaryStatus = useSelector((state) => state.summary.status)
  const [redirectErrorPage, setRedirectErrorPage] = useState(false)

  useEffect(() => {
    if (summaryStatus === 'idle') {
      dispatch(fetchSummaryData())
    }
  }, [summaryStatus, dispatch])

  useEffect(() => {
    let timeoutId
    console.log(summaryStatus)
    if (summaryStatus === 'loading' || summaryStatus === 'failed') {
      timeoutId = setTimeout(() => {
        setRedirectErrorPage(true)
      }, 15000)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [summaryStatus])

  return (
    <>
      <Helmet>
        <title>Usage</title>
      </Helmet>
      <Container maxWidth="xl">
        <RedirectionComponent redirectBool={redirectErrorPage || summaryStatus === 'failed'} redirectPage='/connection-failed' />
        {summaryStatus !== 'succeeded'
          ? (
            <LoadingCircle />
            )
          : (
            <Grid container spacing={12} sx={{ margin: 'auto', justifyContent: 'center', display: 'flex', alignItems: 'center', width: '100%' }} >
              <Usage />
              <Recommandations />
              <Routes />
            </Grid>
            )}
      </Container >
    </>
  )
}
