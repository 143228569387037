import React from 'react'
import { useSelector } from 'react-redux'
import i18n from 'i18next'
import { Box, Grid, Typography } from '@mui/material'
import TopRecommandations from '../customers/TopRecommandations'
import RecommandationSummary from '../customers/usage/RecommandationSummary'

export default function Recommandations () {
  const summaryData = useSelector((state) => state.summary.data)
  const translation = i18n.getDataByLanguage(i18n.resolvedLanguage).summary.recommendations

  return (
    <Grid sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', margin: 'auto', width: '100%' }}>
      <Typography style={{ fontFamily: 'Arial', fontSize: 28, fontWeight: 'bold', color: '#121212', fontStyle: 'italic', marginTop: '50px' }}>
        {translation.title}
      </Typography>
      <Typography
        variant="h4"
        fontWeight="bold"
        component="div"
        sx={{
          borderBottom: '1px lightgrey solid',
          marginTop: 2,
          width: '100%'
        }}
      />
      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto', width: '100%', marginTop: '50px' }}>
        <RecommandationSummary
          title={translation.summary.title}
          subtitle=''
          description=''
          data={[
            { value: summaryData.usage.recommendations.number, label: translation.summary.label[0] },
            { value: summaryData.usage.recommendations.recommendation_per_profile.toFixed(), label: translation.summary.label[1] }
          ]}
        />
        {summaryData.top_recommendations.brands.length >= 2
          ? <Box sx={{ display: 'flex', marginTop: '40px', gap: '40px', width: '100%', justifyContent: 'center' }}>
            <TopRecommandations title={translation.top.title[0]} type={'product'} subheader={''} data={summaryData.top_recommendations.products} isAlone={false}/>
            <TopRecommandations title={translation.top.title[1]} type={'brand'} subheader={''} data={summaryData.top_recommendations.brands} isAlone={false} />
          </Box>
          : <Box sx={{ display: 'flex', marginTop: '40px', gap: '40px', width: '100%', justifyContent: 'center' }}>
            <TopRecommandations title={translation.top.title[0]} type={'product'} subheader={''} data={summaryData.top_recommendations.products} isAlone={true} />
          </Box>
        }
      </Grid>
    </Grid>
  )
}
