import React, { useState } from 'react'
// @mui
import { alpha } from '@mui/material/styles'
import {
  Box,
  Divider,
  Typography,
  MenuItem,
  Button,
  Popover
} from '@mui/material'
// mocks_

import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'

// ----------------------------------------------------------------------

export default function AccountPopover () {
  const navigate = useNavigate()
  const [open, setOpen] = useState(null)
  const clientLogo = localStorage.getItem('companyLogoUrl') || ''
  const clientMail = localStorage.getItem('email') || ''
  const clientCompany = localStorage.getItem('company') || ''

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  async function handleLogout () {
    try {
      await Auth.signOut()
      window.location.reload()
      navigate('/login')
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.1)
            }
          })
        }}
      >
        <img
          src={clientLogo}
          style={{
            width: '100%',
            borderRadius: '8px',
            maxWidth: '125px'
          }}
        />
      </Button>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75
            }
          }
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {clientCompany}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {clientMail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  )
}
