import { Typography, Card, Box, Grid } from '@mui/material'
import LinearProgressWithLabel from '../../sections/@dashboard/app/LinearProgress'
import { useSelector } from 'react-redux'
import i18n from 'i18next'

export default function CustomerFeedbacks () {
  const summaryData = useSelector((state) => state.summary.data)
  const translation = i18n.getDataByLanguage(i18n.resolvedLanguage).summary.routes.rate

  return (
    <Card
      sx={{
        textAlign: 'center',
        bgcolor: '#E9EFF6',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '72%',
        boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Typography style={{ fontFamily: 'Arial', fontSize: '1.563em', fontWeight: 'bold', color: '#121212', paddingTop: '3%' }}>
        {translation.title}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '90%', textAlign: 'left', borderBottom: '1.5px dashed grey' }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: '40% 55%', width: '80%', justifyContent: 'center', paddingTop: '3%', gap: '2%', paddingBottom: '2%', paddingRight: '10%', paddingLeft: '10%' }}>
          <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Typography fontSize={'1.3em'} color='#17385F' sx={{ fontWeight: 'bold' }}>
              {translation.satisfaction}
            </Typography>
            <Typography fontSize={'1em'} color='#17385F' sx={{ fontWeight: 'regular', textAlign: 'left', fontStyle: 'italic', alignSelf: 'start' }}>
              {translation.average}
            </Typography>
          </Box>
          <LinearProgressWithLabel variant="determinate" size={13} value={summaryData.notes.total.positive[1] * 100} />
        </Box>
      </Box>
      <Grid sx={{ display: 'flex', gridTemplateColumns: '45% 45%', width: '90%', gap: '10%', justifyContent: 'space-between', textAlign: 'start', marginTop: '3%', marginBottom: '3%' }}>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Typography fontSize={'1.3em'} color='#17385F' sx={{ fontWeight: 'bold', marginTop: '-0.3em' }}>
            {translation.titles[1]}
          </Typography>
          <LinearProgressWithLabel variant="determinate" size={7} value={summaryData.notes.questions.positive[1] * 100} />
          <Typography fontSize={'1em'} color='#17385F' sx={{ fontWeight: 'regular', marginTop: '-0.3em' }}>
            {`${String(summaryData.notes.questions.positive[0] + summaryData.notes.questions.negative[0])} ${translation.feedbacks} ${String(summaryData.notes.questions.grader_count)} ${translation.users}`}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Typography fontSize={'1.3em'} color='#17385F' sx={{ fontWeight: 'bold', marginTop: '-0.3em' }}>
            {translation.titles[0]}
          </Typography>
          <LinearProgressWithLabel variant="determinate" size={7} value={summaryData.notes.scan.positive[1] * 100} />
          <Typography fontSize={'1em'} color='#17385F' sx={{ fontWeight: 'regular', marginTop: '-0.3em' }}>
            {`${String(summaryData.notes.scan.positive[0] + summaryData.notes.scan.negative[0])} ${translation.feedbacks} ${String(summaryData.notes.scan.grader_count)} ${translation.users}`}
          </Typography>
        </Box>
      </Grid>
    </Card>
  )
}
