import { Donut, Chart } from '../../sections/@dashboard/app'
import createHistory, { calculateAverageNewUsersPerDay } from '../chart/createHistory'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import i18n from 'i18next'
import { Grid, Box, Typography, Card } from '@mui/material'
import KeyInsights from '../customers/usage/KeyInsights'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import ProfileType from '../customers/usage/ProfileType'

export default function Usage () {
  const [timeScale, setTimeScale] = useState(0)
  const summaryData = useSelector((state) => state.summary.data)
  const translation = i18n.getDataByLanguage(i18n.resolvedLanguage).summary
  const history = createHistory(summaryData.usage.users.history)

  const handleTimeScaleChange = (event) => {
    setTimeScale(parseInt(event.target.value))
  }

  return (
    <Grid sx={{ marginTop: '50px', justifyContent: 'center', display: 'flex', flexDirection: 'column', margin: 'auto', width: '100%' }}>
      <Typography style={{ fontFamily: 'Arial', fontSize: 35, fontWeight: 'bold', color: '#121212' }}>
        {translation.title}
      </Typography>
      <Typography style={{ fontFamily: 'Arial', fontSize: 28, fontWeight: 'bold', color: '#121212', fontStyle: 'italic', marginTop: '50px' }}>
        {translation.usage.title}
      </Typography>
      <Typography
        variant="h4"
        fontWeight="bold"
        component="div"
        sx={{
          borderBottom: '1px lightgrey solid',
          width: '100%',
          marginTop: 2
        }}
      />
      <Grid width='100%' height='100%' sx={{ margin: 'auto' }}>
        <Box style={{ margin: 'auto', display: 'grid', gridTemplateColumns: '66% 18%', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '50px', gap: '4.5%' }}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ backgroundColor: 'white', minWidth: '460px', width: '100%', padding: 2, borderRadius: 2, boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)', alignItems: 'center', textAlign: 'center' }}>
              <Chart
                title={translation.usage.chart.title}
                dataType={translation.usage.chart.datatype}
                chartLabels={Object.keys(history[timeScale])}
                chartData={[
                  {
                    name: 'Number',
                    type: 'area',
                    data: [
                      {
                        name: translation.usage.chart.cumulative,
                        data: Object.values(history[timeScale + 3])
                      },
                      {
                        name: translation.usage.chart.evolution,
                        data: Object.values(history[timeScale])
                      }
                    ]
                  },
                  {
                    yaxis: [
                      {
                        opposite: true,
                        title: {
                          text: translation.usage.chart.yaxis.right
                        },
                        min: Object.values(history[timeScale + 3])[0],
                        tickAmount: 4,
                        forceNiceScale: true
                      },
                      {
                        title: {
                          text: translation.usage.chart.yaxis.left
                        },
                        min: Math.min(...Object.values(history[timeScale])),
                        tickAmount: 4,
                        forceNiceScale: true
                      }
                    ],
                    stroke: {
                      curve: 'straight',
                      dashArray: [5, 0]
                    },
                    fill: {
                      opacity: 0,
                      type: 'gradient',
                      gradient: {
                        opacityFrom: [0.3, 0.4],
                        opacityTo: [0.2, 0.4]
                      }
                    }
                  }
                ]}
                style={{ minWidth: '440px', width: '80%', margin: 'auto', backgroundColor: 'white' }}
              />
              <FormControl style={{ marginTop: 15 }} >
                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group" sx={{ justifyContent: 'center' }} onChange={handleTimeScaleChange} defaultValue={0}>
                  <FormControlLabel value={0} control={<Radio size="small" sx={{ '& .MuiSvgIcon-root': { fontSize: '15px' } }} />} label={<Typography variant="body2" fontWeight={'bold'} color={'#5381B8'}>{translation.usage.chart.timescale['7days']}</Typography>} />
                  <FormControlLabel value={1} control={<Radio size="small" sx={{ '& .MuiSvgIcon-root': { fontSize: '15px' } }} />} label={<Typography variant="body2" fontWeight={'bold'} color={'#5381B8'}>{translation.usage.chart.timescale['30days']}</Typography>} />
                  <FormControlLabel value={2} control={<Radio size="small" sx={{ '& .MuiSvgIcon-root': { fontSize: '15px' } }} />} label={<Typography variant="body2" fontWeight={'bold'} color={'#5381B8'}>{translation.usage.chart.timescale.ever}</Typography>} />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
          <KeyInsights
            title={translation.usage.insights.title}
            subtitle=''
            description=''
            data={[
              { value: summaryData.usage.users.number, label: translation.usage.insights.label[0] },
              { value: calculateAverageNewUsersPerDay(summaryData.usage.users.history), label: translation.usage.insights.label[1] }
            ]}
            width='100%'
            height='auto'
          />
        </Box>
      </Grid>
      <Box sx={{ margin: 'auto', display: 'grid', gridTemplateColumns: '44% 50%', gap: '6%', width: '73%', justifyContent: 'center', textAlign: 'center', marginTop: '50px' }}>
        <Card
          sx={{
            display: 'flex',
            bgcolor: '#E9EFF6',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '150px',
            minWidth: '150px',
            boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)'

          }}
        >
          <Box>
            <Typography style={{ fontFamily: 'Arial', fontSize: 25, fontWeight: 'bold', color: '#121212' }}>
              {translation.usage.profile.donut.title}
            </Typography>
            <Box width={'100%'} margin={'auto'}>
              <Donut
                title={''}
                subheader={''}
                donutLabels={[translation.usage.profile.donut.labels[1], translation.usage.profile.donut.labels[0]]}
                donutData={[Number(summaryData.profiles.genders.female[1].toFixed(2)) * 100, Number((summaryData.profiles.genders.male[1].toFixed(2))) * 100]}
                withBackground={false}
              />
            </Box>
          </Box>
        </Card>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
          <ProfileType
            title={translation.usage.profile.boxes.title[0]}
            subtitle={translation.usage.profile.boxes.subtitle}
            description=''
            data={[
              { value: summaryData.profiles.age.male.toFixed(), label: translation.usage.profile.boxes.data.subtitles[0] },
              { value: (summaryData.profiles.height.male / 100).toFixed(2), label: translation.usage.profile.boxes.data.subtitles[1] },
              { value: summaryData.profiles.weight.male.toFixed(), label: translation.usage.profile.boxes.data.subtitles[2] }
            ]}
          />
          <ProfileType
            title={translation.usage.profile.boxes.title[1]}
            subtitle={translation.usage.profile.boxes.subtitle}
            description=''
            data={[
              { value: summaryData.profiles.age.female.toFixed(), label: translation.usage.profile.boxes.data.subtitles[0] },
              { value: (summaryData.profiles.height.female / 100).toFixed(2), label: translation.usage.profile.boxes.data.subtitles[1] },
              { value: summaryData.profiles.weight.female.toFixed(), label: translation.usage.profile.boxes.data.subtitles[2] }
            ]}
          />
        </Box>
      </Box>

    </Grid>
  )
}
