import { Helmet } from 'react-helmet-async'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Button, Typography, Container, Box } from '@mui/material'

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: '15%'
}))

// ----------------------------------------------------------------------

export default function ConnectionFailedPage () {
  console.log('redirection ended')
  return (
    <>
      <Helmet>
        <title> 500 Error with server response</title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, this page could not load!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, an error has occurred while retrieving the data from the server. Try to refresh the page or contact Kleep support.
          </Typography>

          <Button to="/" size="large" variant="contained" component={RouterLink} style={{ marginTop: '50px' }}>
            Reload page
          </Button>
        </StyledContent>
      </Container>
    </>
  )
}
