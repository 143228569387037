import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { SummaryAPI } from '../services/API/SummaryAPI'

export const fetchSummaryData = createAsyncThunk(
  'summary/getSummaryData',
  async () => {
    const response = await SummaryAPI.findSummaryData()
    return response
  }
)

export const summarySlice = createSlice({
  name: 'summary',
  initialState: {
    data: {},
    status: 'idle'
  },
  reducers: {
    resetData: (state) => {
      state.data = {}
      state.status = 'idle'
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSummaryData.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchSummaryData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
      })
      .addCase(fetchSummaryData.rejected, (state) => {
        state.status = 'failed'
      })
  }
})

export const { resetData } = summarySlice.actions
export default summarySlice.reducer
