import React from 'react'
// @mui
import { Typography, Box, Grid } from '@mui/material'
// components
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

export default function TopRecommandations ({ title, type, data, isAlone }) {
  const summaryData = useSelector((state) => state.summary.data)

  return (
    <Grid
      sx={{
        borderRadius: '20px',
        backgroundColor: '#E9EFF6',
        display: 'flex',
        flexDirection: 'column',
        width: isAlone ? '50%' : '42%',
        alignItems: 'center',
        textAlign: 'center',
        boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.1)'
      }}
    >
      <Typography style={{ fontFamily: 'Arial', fontSize: '1.563em', fontWeight: 'bold', color: '#121212', marginTop: '8%' }} width={'55%'}>
        {title}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '6%',
          height: '100%',
          marginBottom: '2%'
        }}
      >
        {data.map((v, idx) => (
          <Box
            key={idx}
            sx={{
              width: v.title ? '92%' : '78%',
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
              minWidth: '5%',
              marginBottom: 'auto',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', width: '75%' }}>
              <Typography style={{ fontFamily: 'Arial', fontSize: '1em', fontWeight: 'bold', color: '#121212' }}>
                {idx + 1}.{' '}
                {isAlone ? '' : v.title ? String(v.brand).toUpperCase() : v.brand}
                {isAlone ? v.title : v.title && `: ${v.title}`}
              </Typography>
            </Box>

            <Typography fontWeight={'bold'} fontSize={'1em'} color={'#5381B8'} textAlign={'right'} width={v.title ? 'auto' : '40%'}>
              {type === 'product' ? v.count + ' (' + ((v.count / summaryData.top_recommendations.product_list_size) * 100).toFixed(1) + '%)' : v.count + ' (' + ((v.count / summaryData.top_recommendations.brand_count_size) * 100).toFixed(1) + '%)'}
            </Typography>
          </Box>
        ))}
      </Box>
    </Grid >
  )
}

TopRecommandations.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  isAlone: PropTypes.bool.isRequired
}
